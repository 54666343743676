import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import Mailto from '../components/Mailto';
import LinkTo from '../components/LinkTo';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';

const CGVPage: React.FC = () => (
  <Layout
    title="FAQ"
    crumbs={[
      { name: 'Accueil', path: '/' },
      { name: 'FAQ', path: '/faq/' },
    ]}
  >
    <SEO
      title="FAQ | Poupée Odette"
      description="FAQ, Aide &amp; Support"
      keywords={['FAQ', 'questions', 'aide', 'support']}
    />

    <Section>
      <p>
        Si vous ne trouvez pas votre bonheur, écrivez-nous à{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        nous vous répondrons sous quelques heures.
      </p>
      <br />
      <h3>Poupée Odette pour quel âge ?</h3>
      <p>
        Poupée Odette est considérée comme objet de décoration à utiliser sous
        la surveillance d'un adulte.
      </p>
      <p>
        La poupée possède des bras, des jambes et des cheveux fins et longs
        pouvant être mis à la bouche et comporter un risque pour l'enfant.
        Particulièrement en dessous de 3 ans.
      </p>
      <h3>Quelles sont les dimensions de nos poupées ?</h3>
      <p>
        Nous parlons d'"environ" sur les mesures car chaque poupée est
        artisanale donc unique, les dimensions peuvent varier très légèrement.
      </p>
      <br />
      <p>Le grand modèle (Adèle et Rosa) mesure environ 50 cm de hauteur.</p>
      <p>Le petit modèle (Luna) mesure environ 25 cm de hauteur.</p>
      <h3>
        Quelles sont les matières utilisées pour fabriquer la poupée ? Que
        signifient les labels Oeko-tex et GOTS ?
      </h3>
      <p>
        La recherche de matières saines est un enjeu primordial dans la
        fabrication de Poupée Odette.
      </p>
      <p>
        Notre partenaire principale est une entrepreneure française qui crée ses
        tissus à 100% en France, labellisés GOTS.
      </p>
      <br />
      <p>
        Vous trouverez les matières utilisées pour chaque poupée dans leur page
        dédiée.
      </p>
      <br />
      <p>
        Pour en savoir plus sur GOTS,{' '}
        <LinkTo address="https://www.global-standard.org">cliquez ici</LinkTo>
      </p>
      <p>
        Pour en savoir plus sur Oeko-tex,{' '}
        <LinkTo address="https://www.oeko-tex.com">cliquez ici</LinkTo>
      </p>
      <br />
      <p>
        Pour la moindre question, nous vous répondrons avec plaisir en toute
        transparence, vous pouvez nous écrire sur :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
      </p>
      <h3>Est-il possible de déshabiller / habiller la poupée ?</h3>
      <p>
        Poupée Odette est faite pour ça ! Vous pouvez déshabiller et habiller la
        poupée à volonté, ses vêtements sont faciles à enlever pour toutes les
        petites mains. Une petite astuce : enlever les vêtements par le bas du
        corps plutôt que par la tête, qui est plus volumineuse.
      </p>
      <h3>Est-il possible de personnaliser la poupée ?</h3>
      <p>
        La personnalisation la plus demandée est celle du prénom brodé sur la
        poupée, vous la trouverez{' '}
        <Link to="/shop/broderie-luna-ou-rosa/">ici</Link>
      </p>
      <p>
        Pour toute autre personnalisation, vous pouvez écrire directement à{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
      </p>
      <h3>Comment est-ce que je peux passer commande ?</h3>
      <p>
        Vous pouvez passer votre commande sur notre site internet 24h/24h et
        7j/7j.
      </p>
      <p>
        Pour toute question, vous pouvez nous envoyer un email à :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>,
        nous vous répondrons le plus vite possible, généralement en moins de
        24h.
      </p>
      <h3>
        Quels sont les moyens de paiement acceptés par Poupée Odette ? Est-ce
        sécurisé ?
      </h3>
      <p>
        Poupée Odette vous propose le paiement en ligne par carte bancaire :
        Carte Bleue, Visa, MasterCard et e-carte bleue (Apple Pay). Les
        paiements par carte sont toujours effectués en euros, même si vous
        naviguez dans une autre devise ($, £ ou CHF).
      </p>
      <p>
        Poupée Odette prend la sécurisation des paiements et la confidentialité
        des informations que vous nous donnez très au sérieux. Dans ce but, le
        site utilise un mode sécurisé de paiement SSL (Secure Socket Layer) qui
        permet le cryptage de vos coordonnées bancaires lors de leur
        transmission sur le réseau. Vos données bancaires ne circulent sur le
        réseau internet que sous forme cryptée, c'est à dire qu'elles sont
        codées de manière à les rendre totalement illisibles. Vous repérez que
        la transmission est cryptée par un logiciel dès lors qu'apparaît dans
        votre navigateur le symbole du cadenas. La transaction s'effectue via le
        système de paiement en ligne Stripe, qui seul dispose des informations
        bancaires fournies sur le site au moment du paiement.
      </p>
      <h3>Quel est le délai de fabrication ?</h3>
      <p>
        Chaque poupée est créée de façon artisanale, à 100% à la main et à la
        demande. Votre commande est traitée sous 3 à 20 jours 
        <strong>maximum</strong>, en fonction du nombre de commandes sur la
        période.
      </p>
      <p>
        Si vous souhaitez savoir où nous en sommes dans la fabrication de votre
        poupée ou si c'est une commande urgente, vous pouvez nous écrire à{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
      </p>
      <h3>Comment la poupée est-elle emballée ?</h3>
      <p>
        La poupée est emballée soigneusement dans du papier de soie pailletée et
        dans son carton d'expédition. Quelques petites surprises sont glissées à
        l'intérieur du colis.
      </p>
      <h3>Quelle est votre politique de livraison ?</h3>
      <p>
        Toutes les poupées sont expédiées par Laposte en colis suivi, sans
        assurance supplémentaire. Le délai de livraison de La Poste est en
        général de 2 à 3 jours ouvrés.
      </p>
      <p>
        Dès que votre Poupée Odette est déposée à La Poste, nous vous écrivons
        un email en vous informant du numéro de suivi, vous permettant de suivre
        votre colis jusqu'à la livraison chez vous.
      </p>
      <p>
        Poupée Odette ne peut être tenue pour responsable des retards,
        dégradations et pertes dues à La Poste. Aussi aucun remboursement n'est
        possible. Les produits sont livrés à l'adresse de facturation /
        livraison que vous nous avez indiqué au cours du processus de commande.
      </p>
      <h3>Combien coûte la livraison en France métropolitaine ?</h3>
      <p>
        Le coût de la livraison en France métropolitaine est fixe et indiqué sur
        votre panier.
      </p>
      <p>
        Il peut être de 4,95€ jusqu'à 7,50 € en fonction du contenu de votre
        panier.
      </p>
      <h3>Livrez-vous hors France métropolitaine ?</h3>
      <p>
        Notre site internet ne gère pas encore les envois hors France
        métropolitaine.
      </p>
      <p>
        Si vous souhaitez commander une poupée hors France métropolitaine,
        veuillez nous contacter par email à :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        nous vous donnerons le prix exact de la livraison et nous pourrons
        ajuster le site internet pour que vous puissiez passer commande.
      </p>
      <h3>Comment savoir si ma commande a bien été prise en compte ?</h3>
      <p>
        Après avoir validé votre commande et procédé au paiement sécurisé, un
        e-mail de confirmation vous sera envoyé. Il s'agit d'un récapitulatif de
        commande contenant la nature, la quantité, le prix des produits
        sélectionnés, le mode de paiement et l'adresse de livraison.
      </p>
      <h3>
        Je me suis trompé(e) dans ma commande en cours, comment modifier les
        informations ?
      </h3>
      <p>
        Pas de panique ! Écrivez-nous à{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>{' '}
        en nous indiquant votre numéro de commande et nous nous chargerons du
        reste :)
      </p>
      <h3>Si je veux échanger ou me faire rembourser un article ?</h3>
      <p>
        Conformément à l'article L.121-20 et suivants du Code de la
        Consommation, vous disposez d'un délai de 14 jours ouvrables à compter
        de la date de livraison de votre commande, pour retourner tout article
        ne vous convenant pas. Au-delà de cette échéance, Poupée Odette sera
        libre de refuser l'échange ou le remboursement.
      </p>
      <p>
        Vos demandes devront être formulées à l'adresse email suivante :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
      </p>
      <p>
        L'échange ou le remboursement des articles ne pourra être accepté que si
        les articles commandés nous sont retournés dans leur état d'origine et
        neuf, non détérioré, propre à sa re-commercialisation. Poupée Odette se
        laisse le droit de juger de l'état de l'article renvoyé. Les frais de
        retour restent à votre charge et devra se faire en colis suivi afin de
        pouvoir suivre la traçabilité du colis.
      </p>
      <p>
        Les modèles créés sur mesure, ne sont ni échangeables ni remboursables.
      </p>
      <h3>Faites-vous les soldes ? Est-ce qu'il existe des codes promo ?</h3>
      <p>
        Nos poupées sont au prix juste ce qui signifie que nous ne pouvons pas
        faire de rabais.
      </p>
      <h3>La poupée est-elle lavable en machine à laver ?</h3>
      <p>
        Nous vous conseillons de laver la poupée à froid, à la main. Un
        programme doux, à froid en machine est déconseillé mais si vous avez
        confiance en votre machine à laver, nous vous laissons prendre cette
        décision risquée ! Certaines peuvent faire des dégâts, particulièrement
        sur la laine (les cheveux) et la broderie si le séchage est trop fort.
      </p>
      <h3>Comment puis-je contacter le service client ?</h3>
      <p>
        Pour la moindre question, remarque, bug technique sur le site ou autre,
        vous pouvez nous écrire à :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
      </p>
      <p>
        Ces retours nous serviront pour enrichir cette FAQ, merci pour votre
        aide.
      </p>
    </Section>
  </Layout>
);

export default CGVPage;
